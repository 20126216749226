import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import auth from './middleware/auth';

import ViewAuth from '@/views/Auth';
import ViewUsers from '@/views/Users';
import roleAccess from './middleware/roleAccess';

Vue.use(VueRouter);

const routes = [
  {
    meta: {
      layout: 'main',
      middleware: [
        auth,
      ]
    },
    name: 'Главная',
    path: '/',
    redirect: '/users'
  },

  {
    component: ViewAuth,
    meta: {
      layout: 'empty',
    },
    name: 'Авторизация',
    path: '/login'
  },
  {
    component: () => import(/* webpackChunkName: "verify" */ '@/views/Verify'),
    meta: {
      layout: 'empty',
      middleware: [
      auth,
      ]
    },
    name: 'Тест',
    path: '/verify'
  },
  {
    component: () => import(/* webpackChunkName: "admins" */ '@/views/Admins'),
    meta: {
      layout: 'main',
      middleware: [
        auth, roleAccess
      ],
      roles: store.state.roleAccess.admins.showAll
    },
    name: 'Администраторы',
    path: '/admins',
  },

  {
    component: ViewUsers,
    meta: {
      layout: 'main',
      middleware: [
        auth,
      ]
    },
    name: 'Список пользователей',
    path: '/users',
  },

  {
    component: () => import(/* webpackChunkName: "users-blacklist" */ '@/views/Users/Blacklist'),
    meta: {
      layout: 'main',
      middleware: [
        auth, roleAccess
      ],
      roles: store.state.roleAccess.users.showBlacklist
    },
    name: 'Черный список',
    path: '/users/blacklist',
  },

  {
    component: () => import(/* webpackChunkName: "users-operators" */ '@/views/Users/Operators'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
      ],
    },
    name: 'Операторы',
    path: '/users/operators',
  },

  {
    component: () => import(/* webpackChunkName: "orders" */ '@/views/Orders'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
      ],
    },
    name: 'Заказы',
    path: '/orders',
  },

  {
    component: () => import(/* webpackChunkName: "menu" */ '@/views/Menu'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
      ],
    },
    name: 'Импортируемое меню',
    path: '/menu',
  },

  {
    component: () => import(/* webpackChunkName: "menu-ingredients" */ '@/views/Menu/Ingredients'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
      ],
    },
    name: 'Ингредиенты',
    path: '/menu/ingredients',
  },

  {
    component: () => import(/* webpackChunkName: "menu-expendables" */ '@/views/Menu/Expendables'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
      ],
    },
    name: 'Расходники',
    path: '/menu/expendables',
  },

  {
    component: () => import(/* webpackChunkName: "menu-labels" */ '@/views/Menu/Labels'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
      ],
    },
    name: 'Метки',
    path: '/menu/labels',
  },

  {
    component: () => import(/* webpackChunkName: "menu-groups" */ '@/views/Menu/Groups'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
      ],
    },
    name: 'Группы',
    path: '/menu/groups',
  },

  {
    component: () => import(/* webpackChunkName: "menu-categories" */ '@/views/Menu/Categories'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
      ],
    },
    name: 'Категории',
    path: '/menu/categories',
  },

  {
    component: () => import(/* webpackChunkName: "menu-modifiers" */ '@/views/Menu/Modifiers'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
      ],
    },
    name: 'Групповые модификаторы',
    path: '/menu/modifiers',
  },

  {
    component: () => import(/* webpackChunkName: "menu-recommendations" */ '@/views/Menu/Recommendations'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
      ],
    },
    name: 'Рекомендации',
    path: '/menu/recommendations',
  },

  {
    component: () => import(/* webpackChunkName: "landings" */ '@/views/Landings'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
      ],
    },
    name: 'Лендинги',
    path: '/landings',
  },

  {
    component: () => import(/* webpackChunkName: "landings-history" */ '@/views/Landings/History'),
    meta: {
      layout: 'main',
      middleware: [
        auth, roleAccess
      ],
      roles: store.state.roleAccess.landings.showHistory
    },
    name: 'История компании',
    path: '/landings/history',
  },

  {
    component: () => import(/* webpackChunkName: "landings-seo" */ '@/views/Landings/Seo'),
    meta: {
      layout: 'main',
      middleware: [
        auth, roleAccess
      ],
      roles: store.state.roleAccess.landings.showSeo
    },
    name: 'Настройки SEO',
    path: '/landings/seo',
  },

  {
    component: () => import(/* webpackChunkName: "news" */ '@/views/News'),
    meta: {
      layout: 'main',
      middleware: [
        auth, roleAccess
      ],
      roles: store.state.roleAccess.news.showAll
    },
    name: 'Новости',
    path: '/news',
  },

  {
    component: () => import(/* webpackChunkName: "promos" */ '@/views/Promos'),
    meta: {
      layout: 'main',
      middleware: [
        auth, roleAccess
      ],
      roles: store.state.roleAccess.promos.showAll
    },
    name: 'Акции',
    path: '/promos',
  },

  {
    component: () => import(/* webpackChunkName: "blog" */ '@/views/Blog'),
    meta: {
      layout: 'main',
      middleware: [
        auth, roleAccess
      ],
      roles: store.state.roleAccess.blog.showAll
    },
    name: 'Блог',
    path: '/blog',
  },

  {
    component: () => import(/* webpackChunkName: "promocodes" */ '@/views/Promocodes'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
      ],
    },
    name: 'Промокоды',
    path: '/promocodes',
  },

  {
    component: () => import(/* webpackChunkName: "mailings" */ '@/views/Mailings'),
    meta: {
      layout: 'main',
      middleware: [
        auth, roleAccess
      ],
      roles: store.state.roleAccess.mailings.showAll
    },
    name: 'Рассылки',
    path: '/mailings',
  },

  {
    component: () => import(/* webpackChunkName: "mailings-service-pushes" */ '@/views/Mailings/ServicePushes'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
      ],
    },
    name: 'Сервисные пуши',
    path: '/mailings/service-pushes',
  },

  {
    component: () => import(/* webpackChunkName: "mailings-icons" */ '@/views/Mailings/Icons'),
    meta: {
      layout: 'main',
      middleware: [
        auth, roleAccess
      ],
      roles: store.state.roleAccess.mailings.showIcons
    },
    name: 'Иконки пушей',
    path: '/mailings/icons',
  },

  {
    component: () => import(/* webpackChunkName: "merchants" */ '@/views/Merchants'),
    meta: {
      layout: 'main',
      middleware: [
        auth, roleAccess
      ],
      roles: store.state.roleAccess.merchants.showAll
    },
    name: 'Мерчанты',
    path: '/merchants',
  },

  {
    component: () => import(/* webpackChunkName: "organizations" */ '@/views/Organizations'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
      ],
    },
    name: 'Организации',
    path: '/organizations',
  },

  {
    component: () => import(/* webpackChunkName: "organizations-tags" */ '@/views/Organizations/Tags'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
      ],
    },
    name: 'Теги',
    path: '/organizations/tags',
  },

  {
    component: () => import(/* webpackChunkName: "organizations-icons" */ '@/views/Organizations/Tags/Icons'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
      ],
    },
    name: 'Иконки тегов',
    path: '/organizations/tags/icons',
  },

  {
    component: () => import(/* webpackChunkName: "terminals" */ '@/views/Terminals'),
    meta: {
      layout: 'main',
      middleware: [
        auth, roleAccess
      ],
      roles: store.state.roleAccess.terminals.showAll
    },
    name: 'Терминалы',
    path: '/terminals',
  },
  {
    component: () => import(/* webpackChunkName: "bonuses" */ '@/views/Bonuses'),
    meta: {
      layout: 'main',
      middleware: [
        auth, roleAccess
      ],
      roles: store.state.roleAccess.bonuses.showAll
    },
    name: 'Бонусы',
    path: '/bonuses',
  },

  {
    component: () => import(/* webpackChunkName: "contacts" */ '@/views/Contacts'),
    meta: {
      layout: 'main',
      middleware: [
        auth, roleAccess
      ],
      roles: store.state.roleAccess.contacts.showAll
    },
    name: 'Контакты',
    path: '/contacts',
  },

  {
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings'),
    meta: {
      layout: 'main',
      middleware: [
        auth, roleAccess
      ],
      roles: store.state.roleAccess.settings.showAll
    },
    name: 'Глобальные настройки',
    path: '/settings',
  },

  {
    component: () => import(/* webpackChunkName: "reviews" */ '@/views/Reviews'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
      ],
    },
    name: 'Отзывы',
    path: '/reviews',
  },

  {
    component: () => import(/* webpackChunkName: "reviews-ratings" */ '@/views/Reviews/Ratings'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
      ],
    },
    name: 'Рейтинги',
    path: '/reviews/ratings',
  },

  {
    component: () => import(/* webpackChunkName: "reviews-qa" */ '@/views/Reviews/Qa'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
      ],
    },
    name: 'Контроль качества - Рестораны',
    path: '/reviews/qa',
  },

  {
    component: () => import(/* webpackChunkName: "reviews-qa-order" */ '@/views/Reviews/Qa/Orders'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
      ],
    },
    name: 'Контроль качества - заказы',
    path: '/reviews/qa/orders',
  },

  {
    component: () => import(/* webpackChunkName: "media" */ '@/views/Media'),
    meta: {
      layout: 'main',
      middleware: [
        auth, roleAccess
      ],
      roles: store.state.roleAccess.media.showAll
    },
    name: 'Медиафайлы',
    path: '/media',
  },

  {
    component: () => import(/* webpackChunkName: "versions" */ '@/views/Versions'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
      ],
    },
    name: 'Версии приложения',
    path: '/versions',
  },

  {
    component: () => import(/* webpackChunkName: "games" */ '@/views/Games'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
      ],
    },
    name: 'Игры',
    path: '/games',
  },

  {
    component: () => import(/* webpackChunkName: "deliveryconfig" */ '@/views/Deliveryconfig'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
      ],
    },
    name: 'Цены доставки',
    path: '/deliveryconfig',
  },

  {
    component: () => import(/* webpackChunkName: "deliveryconfig-zones" */ '@/views/Deliveryconfig/Zones'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
      ],
    },
    name: 'Зоны доставки',
    path: '/deliveryconfig/zones',
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }

  const middleware = to.meta.middleware;

  const context = {
    $cookies: Vue.$cookies,
    to,
    from,
    next,
    store,
  };
  return middleware[0]({
    ...context
  });
});

export default router;
