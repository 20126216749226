export default {
  state: {
    sortParams: {
      imported: false,
      page: 1,
      per_page: 10,
      phrase: '',
    },
    terminals: [],
  },

  mutations: {
    SET_CURRENT_PAGE_PARAM(state, currentPage) {
      state.sortParams.page = currentPage;
    },

    SET_IMPORTED_PARAM(state, value) {
      state.sortParams.imported = value;
    },

    SET_PER_PAGE_PARAM(state, perPage) {
      state.sortParams.per_page = perPage;
    },

    SET_SEARCH_PHRASE_PARAM(state, phrase) {
      state.sortParams.phrase = phrase;
    },

    SET_TERMINALS(state, terminals) {
      state.terminals = terminals;
    },
  },

  actions: {
    async addTerminal(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/terminals/store', formData);
    },

    async editTerminal(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/terminals/update', formData);
    },

    async getTerminals({ commit, state }, payload = null) {

      //var payload = {'showAll' : '1'};

      if (!payload) {
        commit('SET_CURRENT_PAGE_PARAM', 1);
        commit('SET_IMPORTED_PARAM', false);
        commit('SET_PER_PAGE_PARAM', 10);
        commit('SET_SEARCH_PHRASE_PARAM', '');
      } else {

	if (payload.showAll !== undefined) {

	        if (payload.imported !== undefined) {
        	  commit('SET_IMPORTED_PARAM', payload.imported);
	        }

        	if (payload.searchPhrase !== undefined) {
	          commit('SET_SEARCH_PHRASE_PARAM', payload.searchPhrase);
       	 	}

	} else {

        if (payload.currentPage !== undefined) {
          commit('SET_CURRENT_PAGE_PARAM', payload.currentPage);
        }

        if (payload.imported !== undefined) {
          commit('SET_IMPORTED_PARAM', payload.imported);
        }

        if (payload.perPage !== undefined) {
          commit('SET_PER_PAGE_PARAM', payload.perPage);
        }

        if (payload.searchPhrase !== undefined) {
          commit('SET_SEARCH_PHRASE_PARAM', payload.searchPhrase);
        }

	}
      }

      let url = '/panel/terminals';

      if ((payload) && (payload.showAll !== undefined)) {

      for (let key in state.sortParams) {

	if ((key == 'page') || (key == 'per_page'))
		continue;

        const value = state.sortParams[key];

        if (value) {
          url += `${url.indexOf('?') === -1 ? '?' : '&'}${key}=${value}`;
        }
      }


      } else {

      for (let key in state.sortParams) {
        const value = state.sortParams[key];

        if (value) {
          url += `${url.indexOf('?') === -1 ? '?' : '&'}${key}=${value}`;
        }
      }

      }

      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get(url);

        if (payload?.returnData) {
          return response.data;
        } else {
          commit('SET_TERMINALS', response.data);
        }
      } catch (error) {
        throw error;
      }
    },

    async importTerminals() {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/terminals/import');
    },

    async removeTerminal(_, terminalId) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/terminals/delete', {
        id: terminalId
      });
    },

    async switchSource(_, source) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/terminals/switch-source', {
        source
      });
    }
  },

  getters: {},

  namespaced: true,
};
